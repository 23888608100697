let formChanged = false;

function handleFormChanged() {
  formChanged = true;
}

function confirmNavigation() {
  if (formChanged) {
    return window.confirm('If you go back to the Workspace, any changes that have not been saved will be '
    + 'lost. Are you sure you want to continue to the Workspace?');
  }
  return true;
}


function confirmLock() {
  return window.confirm('Your first, main tables will now be locked. Are you sure you want to submit?');
}

function confirmListLock() {
  return window.confirm('Your data will now be locked, you will no longer be able to change your data. '
    + 'Are you sure you want to continue?');
}

$(() => {
  const $form = $('form input[type=text]');

  $form.each((_i, element) => $(element).data('initial_value', $(element).val()));
  $form.keyup((event) => {
    const $input = $(event.target);
    if ($input.val() !== $input.data('initial_value')) {
      handleFormChanged();
    }
  });

  $('form ').bind('change paste', () => handleFormChanged());
  $('.navigation_link').bind('click', () => confirmNavigation());
  $('.confirm_data_table_lock').bind('click', () => confirmLock());
  $('.confirm_data_list_lock').bind('click', () => confirmListLock());
});
