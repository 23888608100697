$(() => {
  if ($('[data-data-list-version-chooser]').length) {
    $('[data-data-list-version-chooser]')
      .change((e) => {
        if (e.target.value === '5') {
          $('[data-data-list-version-box] :input')
            .attr('disabled', false);
          $('.transgender.dt').attr('disabled', true);
          $('.transgender.pp').attr('disabled', true);
        } else if (e.target.value === '4') {
          $('[data-data-list-version-box] :input')
            .attr('disabled', false);
          $('.transgender').attr('disabled', true);
        } else {
          $('[data-data-list-version-box] :input')
            .prop('checked', false);
          $('[data-data-list-version-box] :input')
            .attr('disabled', true);
        }
      });

    $('[data-data-list-edge-plus]')
      .change((e) => {
        if (!e.target.checked) {
          $('[data-data-list-version-box] :input')
            .prop('checked', false);
        }
      });

    $('[data-data-list-version-table] :input')
      .change((e) => {
        if (e.target.checked) {
          $('[data-data-list-edge-plus]')
            .prop('checked', true);
        }
      });
  }
});
