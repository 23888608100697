(function ($) {
  $.fn.CanvasHack = function () {
    const canvases = this.find('canvas').filter(function () {
      return $(this).css('position') === 'absolute';
    });

    canvases.wrap(function () {
      const canvas = $(this);
      const div = $('<div />').css({
        position: 'absolute',
        top: canvas.css('top'),
        left: canvas.css('left'),
      });
      canvas.css({
        top: '0',
        left: '0',
      });
      return div;
    });

    return this;
  };
}(jQuery));
