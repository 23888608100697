import EdgeChartTypes from './chart_types';

(function ($) {
  $.fn.edgeCharts = function (type, data, dataLabels, ticks, barPecentage, categoryPercentage) {
    // fix & char
    const replacedTicks = ticks.map(element => (typeof element === 'string' ? element.replace(/amp;/g, '') : element));

    return this.each(() => {
      if (!type || Object.keys(EdgeChartTypes).includes(type) < 0) {
        type = 'horizontal_bar';
      } else if (!type || type === 'projection_scenario_simulator') {
        EdgeChartTypes.projection_scenario_simulator(
          this,
          data,
          dataLabels,
          replacedTicks,
          barPecentage,
          categoryPercentage,
        );
      } else {
        EdgeChartTypes[type](this, data, dataLabels, replacedTicks, barPecentage, categoryPercentage);
      }
    });
  };
}(jQuery));


(function ($) {
  $.fn.edgeRetentionChart = function (type, data, dataLabels, ticks, trend1, trend2) {
    return this.each(() => {
      if (!type || type === 'retentions_chart') {
        EdgeChartTypes.retentions_chart(this, data, dataLabels, ticks, trend1, trend2);
      } else if (type === 'retentions_chart_noanot') {
        EdgeChartTypes.retentions_chart_noanot(this, data, dataLabels, ticks, trend1, trend2);
      }
    });
  };
}(jQuery));

(function ($) {
  $.fn.edgePlusCharts = function (type, data, dataLabels, ticks, showTicks, key) {
    return this.each(() => {
      if (!type || Object.keys(EdgeChartTypes).includes(type) < 0) {
        type = 'horizontal_bar';
      }

      EdgeChartTypes[type](this, data, dataLabels, ticks, showTicks, key);
    });
  };
}(jQuery));
