export default {
  latino: '#a52b39',
  notLatino: '#a19588',
  bBlack: '#1c2543',
  native: '#417ec1',
  veryDarkGreen: '#31746e',
  pink: '#d799D2',
  darkYellow: '#f1db5d',
  darkViolet: '#9f0fa5',
  bPink: '#d88499',
  bGreen: '#183a37',
  bLGreen: '#98b998',
  LBlue: '#6d81c0',
  dBrown: '#52161d',
  dBlue: '#305e92',
  dGrey: '#c7bfb8',
  vDkGrey: '#403f4c',
  vDkYellow: '#e7c714',
  vDkBrown: '#7c202b',
  vDkPink: '#bd57b5',
  VDkBlack: '#201f26',
  vLkBrown: '#d16f7b',
  vLBlue: '#b3cbe6',
  vDkViolet: '#84347e',
  vDLGreen: '#6dc2ba',
  vDBGreen: '#534b42',
  red: '#AC0235',
  red1: '#750223',
  blue: '#4879a6',
  maroon1: '#9A353D',
  normalGrey: '#A09589',
  darkBlue: '#1F2542',
  maroon: '#800000',
  white: '#ffffff',
  e_blue: '#5E6E7D',
  turquoise: '#07BEBE',
  turquoise_1: '#00898C',
  turquoise_2: '#005D60',
  turquoise_hover: '#08d1d1',
  black_2: '#bfbfbf',
  lightGray: '#b1b1b1',
  gray: '#949494',
  darkGray: '#595959',
  black: '#000000',
  black1: '#bfbfbf',
  yellow: '#FBBB26',
  green: '#a3cc14',
  annotations: {
    red: '#d9321d',
  },
  orange: '#ff8c00',
  darkGreen: '#009900',
  darkblue1: '#2f4858',
  zorbaBrown: '#A19588',
  whaleBlue: '#1C2543',
  curiousBlue: '#417EC1',
  genoaGreen: '#31746E',
  plumViolet: '#D799D2',
  porticaYellow: '#F1DB5D',
  spunpearlBlue: '#9F9FA5',
  charmRed: '#D88499',
  tealGreen: '#183A37',
  springGreen: '#98B998',
  danubeBlue: '#6D81C0',
  chocolateRed: '#52161D',
  endeavourBlue: '#305E92',
  cloudYellow: '#C7BFB8',
  payneGrey: '#403F4C',
  moonYellow: '#E7C714',
  scarletRed: '#7C202B',
  fuchsiaViolet: '#BD57B5',
  russianBlack: '#201F26',
  seaPink: '#DA6F7B',
  lightbBlue: '#B3CBE6',
  purple: '#84347E',
  monteCarlo: '#6DC2BA',
  mondoBrown: '#534B42',
  solidred: '#ff0000',
  sangriared: '#a00000',
  havelockblue: '#527DDE',
  Chambrayblue: '#2c5981',
  mercury: '#e7e6e6',
  perception: {
    p1: '#AC0235',
    p2: '#c72858',
    p3: '#005D60',
    p4: '#219498',
    p5: '#07bebe',
    p6: '#84d9d9',
  },
};
